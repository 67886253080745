<template>
  <v-container fluid ma-0 pa-0>
    <Banner />
    <Feature />
    <ProductList v-if="products && products.length" :products="products" />
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Banner from '@/components/default/pages/Banner.vue'
import Feature from '@/components/default/pages/Feature.vue'
import ProductList from '@/components/default/pages/program/ProductList.vue'

export default {
  components: {
    Banner,
    Feature,
    ProductList
  },
   computed: {
    ...mapState({
      products: (state) => state.microsite.products
    })
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    ...mapActions({
      getProducts: 'microsite/getProducts'
    })
  }
}
</script>
