<template>
  <v-sheet v-if="currentBanner.banner && currentBanner.title" class="hero-bg" min-height="600" :style="{backgroundImage: `url(${currentBanner.banner})`}">
    <v-container class="py-6 py-sm-12 py-lg-16 text-center" style="padding-top:70px !important;">
      <v-responsive max-width="800" class="mx-auto background-shadow">
        <h1 class="text-h1 mt-3 mx-4 white--text" style="font-size: 3.7rem !important;">{{ currentBanner.title | uppercase }}</h1>
        <div v-if="currentBanner.title" class="text-h6 font-weight-medium mt-3 mx-4 white--text">Play Travel Protection protects your vacation investment against unforeseen events like a hurricane, sickness, injury, flight cancellation, job loss and much more.</div> 
        <div v-if="currentBanner.title" class="text-h6 font-weight-bold mt-3 mb-3 mx-4 white--text">Group Coverage Protects Everyone in Your Traveling Party For One Low Price.</div> 
      </v-responsive>
      <v-responsive max-width="800" class="mx-auto">
        <v-btn 
          v-if="currentBanner.title"
          id="app"
          color="#044372"
          depressed
          x-large
          class="mt-4 pl-10 pr-10 white--text rounded-0 darken-4"
          @click="orderPage"
        >Get Quote</v-btn>
      </v-responsive>
    </v-container>
  </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentBanner: 'microsite/currentBanner'
    })
  },
  methods: {
    orderPage() {
      window.location.href = '/order'
    }
  }
}
</script>

<style scoped>
.hero-bg:after {
  background-size: cover;
  background-position: center;
  min-height: 600px;
  opacity:0.5;
  filter:alpha(opacity=40);
}
.dropshadow {
  text-shadow: 2px 2px rgb(4, 67, 114);
}

.background-shadow {
  background-color: rgba(4, 67, 114, .3);
}
</style>