<template>
  <v-container class="mb-0 pb-2">
    <div v-for="(page, index) in pageData" v-if="page.Content" :key="index" class="py-0">
      <div v-if="page.Content" class="text-center">
        <h2 v-if="page.Heading" class="text-h3 text-lg-h2" v-html="page.Heading" />
        <v-responsive v-if="page['Sub-Heading']" max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4" v-html="page['Sub-Heading']" />
        </v-responsive>
        <div v-if="page.Content" class="text-left my-2" v-html="page.Content" />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentPage: 'microsite/currentPage'
    }),
    pageData() {
      return this.currentPage ? this.currentPage.PageData : null
    }
  }
}

</script>