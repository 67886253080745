<template>
  <v-container class="py-1">
    <v-responsive max-width="1200" class="mx-auto text-left pl-5 mb-1">
      <h2 class="text-h4 mb-0">Products</h2>
    </v-responsive>
    <v-card
      v-for="(displayProduct, index) in displayProducts"
      :key="index"
      class="d-flex flex-column flex-md-row ma-4 mt-0"
      outlined
      elevation="2"
    >
      <div class="flex-grow-1 pa-4">
        <v-row dense class="secondary--text" v-html="displayProduct.DiscriptionLong"></v-row>
      </div>
    </v-card>
  </v-container>
</template>
<script>

export default {
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
 computed: {
   displayProducts() {
      const allProducts = this.products

      return allProducts.filter((prod) =>
        (prod.IsDisplay === true)
      )
   }
 }

}
</script>

<style scoped>

</style>