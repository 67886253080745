<template>
  <v-container fluid ma-0 pa-0>
    <Banner />
    <Feature />
    <router-view :key="$route.fullPath"></router-view>
  </v-container>
</template>
<script>
import Banner from '@/components/default/pages/Banner.vue'
import Feature from '@/components/default/pages/Feature.vue'

export default {
  components: {
    Banner,
    Feature
  }
}
</script>
